body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  height: 5rem;
  width: 5rem;
  padding: 0px;
  margin: 0px;
  border: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Hero Welcome */
.welcomeHome {
  text-align: center;
  font-size: 75px;
  font-weight: bold;
  color: white;
  padding: 0rem;
  margin-left: 17rem;
  margin-right: 17rem;
  padding-top: 7rem;
  padding-bottom: 4rem
}

#gradientTextHome {
  background: linear-gradient(to right, #FF0000, #F00080, #C000C0, #8000F0, #4000FF, #0040FF, #0080C0, #00C080, #00FF40, #40FF00);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Style for Date Input */
input[type="date"], input[type="email"] {
  border: none;
  outline: none;
  background: #f0f0f0;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
}

input[type="email"]:focus, input[type="date"]:focus{
  border-bottom: 2px solid #FF5733;
  outline: none;
}

/* Style for Radio Buttons */
input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px rgba(0,0,0,.2);
  transition: 0.5s;
  cursor: pointer;
}
input[type="radio"]:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;    
}
input[type="radio"]:checked:before {
  background: #f8f9fa;
}


.welcomeWords {
  text-align: center;
}

.welcomeWords h1 {
  font-size: 75px;
  font-weight: bolder;
  padding-bottom: 0%;
  margin-bottom: 0%;
}

.welcomeWords h2 {
  font-size: 60px;
  font-weight: bolder;
  padding-top: 0%;
  margin-top: 0%;
  padding-bottom: 0%;
  margin-bottom: 0%;
}

.welcomeWords h3 {
  color: #6e6e73;
  font-size: 30px;
  font-weight: bold;
  padding-top: 1rem;
  margin-top: 0%;
}

.mainServicesSection {
  display: flex;
  margin-top: 5rem;
  margin: 3rem;
  justify-content: space-around;
  background-color: #161617;
  border-radius: 3rem;
  height: 50vh; /* Adjust as needed */
}


a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: rgb(255, 123, 0);
}

.navigationBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  padding: 1rem 2rem;
  position: sticky;
  top: 2rem;
  backdrop-filter: blur(70px);
  border-radius: 15px;
  margin-left: 35rem;
  margin-right: 35rem;
  margin-top: 0rem;
  z-index: 9999;
}

.navigationBar a {
  text-decoration: none;
  color: #f0f0f0;
  font-weight: bold;
  font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1.15rem;
  margin: 0 1.5rem;
}

.navigationBar a:hover {
  color: #0070c9;
}

.menuButton {
  display: none;
}

/* Card Styling */
.card {
  width: 300px;
  height: 350px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content to the top */
  text-align: center;
  color: #1d1d1f;
  font-family: 'San Francisco', sans-serif;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-tagline {
  margin: 20px 0;
  font-size: 20px;
  font-weight: 600;
}

/* .card-action {
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;

} */

.card-action {
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 320;
  background-color: #fff;
  color: #1d1d1f;
  outline-color: #1d1d1f;
  outline-width: 2px;
  outline-style: solid;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-action:hover {
  background-color: #1d1d1f;
  color: #fff;
  outline-color: #1d1d1f;
  outline-width: 2px;
  outline-style: solid;
}

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

/* Scrollable Services Section */
.scrollableServicesSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  margin: 3rem;
  background-color: #161617;
  border-radius: 3rem;
  height: 50vh;
}

.fixedLetters {
  color: #f8f9fa;
  padding: 10px;
  font-size: 5em;
  text-align: right;
}

.scrollableWords {
  color: #f8f9fa;
  overflow-y: scroll;
  padding: 10px;
  font-size: 6rem;
  height: 100px;
  text-align: left;
}

.scrollableWords p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  opacity: 0.5;
}

/* Appintment Scheduling Section */
label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #f8f9fa;
  margin-bottom: 1rem;
}

input[type="date"], input[type="radio"] {
  margin-top: .5rem;
}

input[type="radio"] {
  margin-right: .5rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal h1 {
  color: #f8f9fa;
}

.modal p {
  color: #fff;
}


.modal .card-action {
    padding: 10px 20px;
    color: #f0f0f0;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 320;
    background-color: #1d1d1f;
    outline-color: #1d1d1f;
    outline-width: 2px;
    outline-style: solid;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal .card-action.bookingBtn {
  color:#161617;
  background-color: #f0f0f0;
}

.modal .card-action.bookingBtn:hover {
  color: #f8f9fa;
  background-color: #161617;
  outline-width: 1px;
  outline-color: #f8f9fa;
  outline-style:solid;
}

.modal .card-action:hover {
  outline-width: 1px;
  outline-color: #1d1d1f;
  outline-style: dotted;
}
/* End of Appointment Scheduling Section */

/* Contact Section */
.translucentSection {
  display: flex;
  margin-top: 5rem;
  margin: 3rem;
  justify-content: space-around;
  background-color: #f8f9fa; /* Adjust opacity as needed */
  border-radius: 3rem;
  height: 50vh; /* Adjust as needed */
}

.translucentSection .innerSection {
  flex: 1;
  margin: 3rem;
  padding: 1rem;
  background-color: #1d1d1f;
  border-radius: 3rem;
  justify-content: center;
  text-align: center;
}

.innerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.innerSection h3 {
  color: #fff;
  font-size: 40px;
  margin: 0;
  padding: 0;
}

.innerSection .card-action {
  margin: 2rem;
  background-color: #161617;
  color: #f8f9fa;
  outline-color: #f8f9fa;
}

.innerSection .card-action.mapBtn {
  cursor:alias;
}

.innerSection .card-action:hover {
  background-color: #f8f9fa;
  color: #161617;
  outline-color: #f8f9fa;
  outline-style: solid;
}

/* Footer */
.footer {
  background-color: #f8f9fa;
  color: #1d1d1f;
  text-align: center;
  padding: 20px;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.footer a {
  color: #1d1d1f;
  text-decoration: none;
  margin: 0 10px;
}

.footer a:hover {
  color: #0056b3;
}

/* Responsive for Mobile Devices */

/* Responsive layout for devices having width between 280px and 359px */
@media (min-width: 280px) and (max-width: 359px) {
  /* Overall Structure and Layout */
  body {
    font-size: 1rem; /* Adjust base font size for smaller screens */
  }

  .menuButton {
    display: none;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border-style: solid;
    border-color: #161617;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: xx-small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    z-index: 999;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .welcomeHome {
    font-size: 20px; /* Reduce hero text size */
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mainServicesSection {
    flex-direction: column; /* Stack services vertically */
    height: auto; /* Remove fixed height */
    background-color: transparent;
  }

  .cardContainer {
    width: 100%; /* Full-width cards */
    margin-bottom: 2rem;
  }

  .scrollableServicesSection {
    flex-direction: row; /* Stack scrollable text blocks */
    height: 20vh;
    border-radius: 12px;
  }

  .fixedLetters {
    font-size: 1em;
  }
  
  .scrollableWords {
    font-size: 1.5em;
  }

  .translucentSection .innerSection {
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
    flex-direction: column;
  }

  .innerSection h3 {
    font-size: 20px;
  }

  .innerSection .card-action {
    margin: 1rem;
  }

  /* Navigation Bar */
  .navigationBar {
    position: fixed;
    top: auto;
    bottom: 2rem;
    /* flex-direction: column; */
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 75%;
  }

  .navigationBar a {
    margin: 1rem 0;
    font-size: small;
  }

  /* Cards */
  .card {
    width: 100%; /* Full-width cards */
  }

  /* Other Adjustments */
  .welcomeWords h2 {
    font-size: 25px;/* Reduce heading sizes for readability */
  }
  .welcomeWords h1 {
    font-size: 40px !important;
  }
  .welcomeWords h3 {
    font-size: 20px !important;
  }

  .modal h1 {
    font-size: 25px; /* Adjust modal heading size */
  }

}
/* End of Responsive layout for devices having width between 280px and 359px */

/* Responsive layout for devices having width between 360px and 374px */
@media (min-width: 360px) and (max-width: 374px) {
  /* Overall Structure and Layout */
  body {
    font-size: 1rem; /* Adjust base font size for smaller screens */
  }

  .menuButton {
    display: none;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border-style: solid;
    border-color: #161617;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: xx-small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    z-index: 999;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .welcomeHome {
    font-size: 30px; /* Reduce hero text size */
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mainServicesSection {
    flex-direction: column; /* Stack services vertically */
    height: auto; /* Remove fixed height */
    background-color: transparent;
  }

  .cardContainer {
    width: 100%; /* Full-width cards */
    margin-bottom: 2rem;
  }

  .scrollableServicesSection {
    flex-direction: row; /* Stack scrollable text blocks */
    height: 30vh;
    border-radius: 12px;
  }

  .fixedLetters {
    font-size: 2.2em;
  }
  
  .scrollableWords {
    font-size: 2.5em;
  }

  .translucentSection .innerSection {
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
  }

  .innerSection h3 {
    font-size: 20px;
  }

  .innerSection .card-action {
    margin: 1rem;
  }

  /* Navigation Bar */
  .navigationBar {
    position: fixed;
    top: auto;
    bottom: 2rem;
    /* flex-direction: column; */
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 83%;
  }

  .navigationBar a {
    margin: 1rem 0;
    font-size: small;
  }

  /* Cards */
  .card {
    width: 100%; /* Full-width cards */
  }

  /* Other Adjustments */
  .welcomeWords h2 {
    font-size: 30px;/* Reduce heading sizes for readability */
  }
  .welcomeWords h1 {
    font-size: 70px !important
  }

  .modal h1 {
    font-size: 25px; /* Adjust modal heading size */
  }

}
/* End of Responsive layout for devices having width between 360px and 374px */

/* Responsive layout for devices having width between 375px and 393px */
@media (min-width: 375px) and (max-width: 393px) {
  /* Overall Structure and Layout */
  body {
    font-size: 1rem; /* Adjust base font size for smaller screens */
  }

  .menuButton {
    display: none;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border-style: solid;
    border-color: #161617;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: xx-small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    z-index: 999;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .welcomeHome {
    font-size: 30px; /* Reduce hero text size */
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mainServicesSection {
    flex-direction: column; /* Stack services vertically */
    height: auto; /* Remove fixed height */
    background-color: transparent;
  }

  .cardContainer {
    width: 100%; /* Full-width cards */
    margin-bottom: 2rem;
  }

  .scrollableServicesSection {
    flex-direction: row; /* Stack scrollable text blocks */
    height: 30vh;
    border-radius: 12px;
  }

  .fixedLetters {
    font-size: 2.5em;
  }
  
  .scrollableWords {
    font-size: 2.7em;
  }

  .translucentSection .innerSection {
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
  }

  .innerSection h3 {
    font-size: 20px;
  }

  .innerSection .card-action {
    margin: 1rem;
  }

  /* Navigation Bar */
  .navigationBar {
    position: fixed;
    top: auto;
    bottom: 2rem;
    /* flex-direction: column; */
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 83%;
  }

  .navigationBar a {
    margin: 1rem 0;
    font-size: small;
    /* border-left: 2rem;
    border-right: 2rem; */
  }

  /* Cards */
  .card {
    width: 100%; /* Full-width cards */
  }

  /* Other Adjustments */
  .welcomeWords h2 {
    font-size: 30px;/* Reduce heading sizes for readability */
  }
  .welcomeWords h1 {
    font-size: 70px !important
  }

  .modal h1 {
    font-size: 25px; /* Adjust modal heading size */
  }

}
/* End of Responsive layout for devices having width between 375px and 393px */

/* Responsive layout for devices having width between 394px and 430px */
@media (min-width: 394px) and (max-width: 430px) {
  /* Overall Structure and Layout */
  body {
    font-size: 1rem; /* Adjust base font size for smaller screens */
  }

  .menuButton {
    display: none;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border-style: solid;
    border-color: #161617;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: xx-small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    z-index: 999;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .welcomeHome {
    font-size: 30px; /* Reduce hero text size */
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mainServicesSection {
    flex-direction: column; /* Stack services vertically */
    height: auto; /* Remove fixed height */
    background-color: transparent;
  }

  .cardContainer {
    width: 100%; /* Full-width cards */
    margin-bottom: 2rem;
  }

  .scrollableServicesSection {
    flex-direction: row; /* Stack scrollable text blocks */
    height: 30vh;
    border-radius: 12px;
  }

  .fixedLetters {
    font-size: 2.5em;
  }
  
  .scrollableWords {
    font-size: 2.8em;
  }

  .translucentSection .innerSection {
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
  }

  .innerSection h3 {
    font-size: 20px;
  }

  .innerSection .card-action {
    margin: 1rem;
  }

  /* Navigation Bar */
  .navigationBar {
    position: fixed;
    top: auto;
    bottom: 2rem;
    /* flex-direction: column; */
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 84.3%;
  }

  .navigationBar a {
    margin: 1rem 0;
    font-size: small;
    /* border-left: 2rem;
    border-right: 2rem; */
  }

  /* Cards */
  .card {
    width: 100%; /* Full-width cards */
  }

  /* Other Adjustments */
  .welcomeWords h2 {
    font-size: 30px;/* Reduce heading sizes for readability */
  }
  .welcomeWords h1 {
    font-size: 70px !important
  }

  .modal h1 {
    font-size: 25px; /* Adjust modal heading size */
  }

}
/* End of Responsive layout for devices having width between 394px and 430px */

/* Responsive layout for devices having width between 431px and 767px */
@media (min-width: 431px) and (max-width: 767px) {
  /* Overall Structure and Layout */
  body {
    font-size: 1rem; /* Adjust base font size for smaller screens */
  }

  .menuButton {
    display: none;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border-style: solid;
    border-color: #161617;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: xx-small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    z-index: 999;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .welcomeHome {
    font-size: 30px; /* Reduce hero text size */
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .mainServicesSection {
    flex-direction: column; /* Stack services vertically */
    height: auto; /* Remove fixed height */
    background-color: transparent;
  }

  .cardContainer {
    width: 100%; /* Full-width cards */
    margin-bottom: 2rem;
  }

  .scrollableServicesSection {
    flex-direction: row; /* Stack scrollable text blocks */
    height: 30vh;
    border-radius: 12px;
  }

  .fixedLetters {
    font-size: 2.5em;
  }
  
  .scrollableWords {
    font-size: 2.8em;
  }

  .translucentSection .innerSection {
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
  }

  .innerSection h3 {
    font-size: 20px;
  }

  .innerSection .card-action {
    margin: 1rem;
  }

  /* Navigation Bar */
  .navigationBar {
    position: fixed;
    top: auto;
    bottom: 2rem;
    /* flex-direction: column; */
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 85%;
  }

  .navigationBar a {
    margin: 1rem 0;
    font-size: small;
    /* border-left: 2rem;
    border-right: 2rem; */
  }

  /* Cards */
  .card {
    width: 100%; /* Full-width cards */
  }

  /* Other Adjustments */
  .welcomeWords h2 {
    font-size: 30px;/* Reduce heading sizes for readability */
  }
  .welcomeWords h1 {
    font-size: 70px !important
  }

  .modal h1 {
    font-size: 25px; /* Adjust modal heading size */
  }

}
/* End of Responsive layout for devices having width between 431px and 767px */

/* Responsive layout for devices having width between 768px and 1023px */
@media (min-width: 768px) and (max-width: 1023px) {
  /* Overall Structure and Layout */
  body {
    font-size: 1rem; /* Adjust base font size for smaller screens */
  }

  .menuButton {
    display: none;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border-style: solid;
    border-color: #161617;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: xx-small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    z-index: 999;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .welcomeHome {
    font-size: 60px; /* Reduce hero text size */
    margin-left: 1rem;
    margin-right: 1rem;
    padding-bottom: 20rem;
  }

  .mainServicesSection {
    flex-direction: column; /* Stack services vertically */
    height: auto; /* Remove fixed height */
    background-color: transparent;
  }

  .cardContainer {
    width: 100%; /* Full-width cards */
    margin-bottom: 2rem;
  }

  .scrollableServicesSection {
    flex-direction: row; /* Stack scrollable text blocks */
    height: 30vh;
    border-radius: 12px;
  }

  .fixedLetters {
    font-size: 5em;
  }
  
  .scrollableWords {
    font-size: 6.4em;
  }

  .translucentSection .innerSection {
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
  }

  .innerSection h3 {
    font-size: 40px;
  }

  .innerSection .card-action {
    margin: 1rem;
  }

  /* Navigation Bar */
  .navigationBar {
    position: fixed;
    top: auto;
    bottom: 2rem;
    /* flex-direction: column; */
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 92%;
  }

  .navigationBar a {
    margin: 1rem 0;
    font-size: large;
    /* border-left: 2rem;
    border-right: 2rem; */
  }

  /* Cards */
  .card {
    width: 100%; /* Full-width cards */
  }

  /* Other Adjustments */
  .welcomeWords h2 {
    font-size: 60px;/* Reduce heading sizes for readability */
  }
  .welcomeWords h1 {
    font-size: 70px !important
  }

  .modal h1 {
    font-size: 25px; /* Adjust modal heading size */
  }

}
/* End of Responsive layout for devices having width between 768px and 1023px */

/* Responsive layout for devices having width between 1024px and 1280px */
@media (min-width: 1024px) and (max-width: 1545px) {
  /* Overall Structure and Layout */
  body {
    font-size: 1rem; /* Adjust base font size for smaller screens */
  }

  .menuButton {
    display: none;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border-style: solid;
    border-color: #161617;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-size: xx-small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    z-index: 999;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
  }

  .welcomeHome {
    font-size: 75px; /* Reduce hero text size */
    margin-left: 1rem;
    margin-right: 1rem;
    padding-bottom: 20rem;
  }

  .mainServicesSection {
    flex-direction: row; /* Stack services vertically */
    height: auto; /* Remove fixed height */
    background-color: #161617;
  }

  .cardContainer {
    width: 100%; /* Full-width cards */
    margin: 2rem;
  }

  .scrollableServicesSection {
    flex-direction: row; /* Stack scrollable text blocks */
    height: 40vh;
    border-radius: 12px;
  }

  .fixedLetters {
    font-size: 5.8em;
  }
  
  .scrollableWords {
    font-size: 7.1em;
  }

  .translucentSection .innerSection {
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
  }

  .innerSection h3 {
    font-size: 40px;
  }

  .innerSection .card-action {
    margin: 1rem;
  }

  /* Navigation Bar */
  .navigationBar {
    position: fixed;
    top: auto;
    bottom: 2rem;
    /* flex-direction: column; */
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 94%;
  }

  .navigationBar a {
    margin: 1rem 0;
    font-size: x-large;
    /* border-left: 2rem;
    border-right: 2rem; */
  }

  /* Cards */
  .card {
    width: 100%; /* Full-width cards */
  }

  /* Other Adjustments */
  .welcomeWords h2 {
    font-size: 70px;/* Reduce heading sizes for readability */
  }
  .welcomeWords h1 {
    font-size: 80px !important
  }

  .modal h1 {
    font-size: 25px; /* Adjust modal heading size */
  }

}
/* End of Responsive layout for devices having width between 1024px and 1280px */

/* End of Responsive for Mobile Devices */