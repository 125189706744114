.ModernizationAssessmentPageWrapper {
    background-color: black;
    background: linear-gradient(to left, #330000 3%, black 100%);
  }

.ModernizationAssessmentPageWrapper .navigationBar {
    background-color: #161617 !important;
}

.ModernizationAssessmentPageWrapper .navigationBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    padding: 1rem 2rem;
    position: sticky;
    top: 2rem;
    backdrop-filter: blur(70px);
    border-radius: 15px;
    margin-left: 30rem;
    margin-right: 30rem;
    margin-top: 0rem;
}

.welcomeModernization {
    text-align: center;
    font-size: 75px;
    font-weight: bold;
    color: white;
    padding: 0rem;
    margin-left: 17rem;
    margin-right: 17rem;
    padding-top: 10rem;
    padding-bottom: 12rem
}

.subHeading {
    text-align: center;
  }
  
  .welcomeWords h1 {
    font-size: 75px;
    font-weight: bolder;
    padding-bottom: 0%;
    margin-bottom: 0%;
  }
  
  .subHeading h2 {
    font-size: 60px;
    color: white;
    font-weight: bolder;
    padding-top: 0%;
    margin-top: 0%;
    padding-bottom: 0%;
    margin-bottom: 0%;

  }

  .subHeading p,li,ol {
    font-size: 30px;
    color: #f0f0f0;
    padding-top: 0%;
    margin-top: 0%;
    padding-bottom: 0%;
    margin-bottom: 0%;

  }

  
  .welcomeWords h3 {
    color: #6e6e73;
    font-size: 30px;
    font-weight: bold;
    padding-top: 1rem;
    margin-top: 0%;
  }
  
  
  .ModernizationAssessmentBody  #whoDiv{
    display: flex;
    margin-top: 5rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.5);
    border-style:solid;
    border-width: 1px;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 3rem;
    padding-top: 6rem;
    height: 40vh; /* Adjust as needed */
  }
  
  .ModernizationAssessmentBody  #whatDiv{
    display: flex;
    margin-top: 5rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.5);
    /* border-color: #FD7E14; */
    border-style: solid;
    border-width: 1px;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 3rem;
    padding-top: 6rem;
    height: 30vh; /* Adjust as needed */
  }
  .ModernizationAssessmentBody  #whenDiv{
    display: flex;
    margin-top: 5rem;
    margin: 3rem;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.5);
    /* border-color: #28A745; */
    border-style: solid;
    border-width: 1px;    
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 3rem;
    padding-top: 6rem;
    height: 30vh; /* Adjust as needed */
  }
  .ModernizationAssessmentBody  #whereDiv{
    display: flex;
    margin-top: 5rem;
    margin: 3rem;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.5);
    /* border-color: #FFC107; */
    border-style: solid;
    border-width: 1px;    
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 3rem;
    padding-top: 6rem;
    height: 25vh; /* Adjust as needed */
  }
  .ModernizationAssessmentBody  #whyDiv{
    display: flex;
    margin-top: 5rem;
    margin: 3rem;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.5);
    /* border-color: #DC3545; */
    border-style: solid;
    border-width: 1px;    
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 3rem;
    padding-top: 6rem;
    height: 20vh; /* Adjust as needed */
  }
  .ModernizationAssessmentBody  #howDiv{
    display: flex;
    margin-top: 5rem;
    margin: 3rem;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, 0.5);
    /* border-color: #6C757D; */
    border-style: solid;
    border-width: 1px;    
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 3rem;
    padding-top: 6rem;
    height: 30vh; /* Adjust as needed */
  }
  
  #gradientTextModAssess{
    background: linear-gradient(to right, #FF0000, #FF3333, #FF6666, #FF9999, #FFCCCC);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  

  a {
    color: black;
    text-decoration: none;
  }
  
  a:hover {
    color: #0070c9 !important;
  }

  /* Responsive for mobile devices */
  @media (min-width: 768px) and (max-width: 1023px) {
    .ModernizationAssessmentPageWrapper .navigationBar {
      position: fixed;
      top: auto;
      bottom: 2rem;
      /* flex-direction: column; */
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 0px;
      padding-bottom: 0px;
      width: 93%;
    }

    .ModernizationAssessmentPageWrapper .navigationBar a {
      margin: 1rem 0;
      font-size: large;
      /* border-left: 0.5rem;
      border-right: 0.5rem; */
    }

    .welcomeModernization {
      font-size: 50px; /* Reduce hero text size */
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .subHeading h2 {
      font-size: 60px;
    }

    .subHeading p {
      font-size: 30px;
    }

    .ModernizationAssessmentBody  #whoDiv, 
    .ModernizationAssessmentBody  #whatDiv,
    .ModernizationAssessmentBody  #whenDiv,
    .ModernizationAssessmentBody  #whereDiv,
    .ModernizationAssessmentBody  #whyDiv,
    .ModernizationAssessmentBody  #howDiv {
      padding-top: 5rem;
      padding-bottom: 6rem;
      margin-right: 1rem;
      margin-left: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media (max-width: 767px) {
    .ModernizationAssessmentPageWrapper .navigationBar {
      position: fixed;
      top: auto;
      bottom: 2rem;
      /* flex-direction: column; */
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 0px;
      padding-bottom: 0px;
      width: 85%;
    }

    .ModernizationAssessmentPageWrapper .navigationBar a {
      margin: 1rem 0;
      font-size: x-small;
      /* border-left: 0.5rem;
      border-right: 0.5rem; */
    }

    .welcomeModernization {
      font-size: 25px; /* Reduce hero text size */
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .subHeading h2 {
      font-size: 50px;
    }

    .subHeading p {
      font-size: 25px;
    }

    .ModernizationAssessmentBody  #whoDiv, 
    .ModernizationAssessmentBody  #whatDiv,
    .ModernizationAssessmentBody  #whenDiv,
    .ModernizationAssessmentBody  #whereDiv,
    .ModernizationAssessmentBody  #whyDiv,
    .ModernizationAssessmentBody  #howDiv {
      padding-top: 5rem;
      padding-bottom: 6rem;
      margin-right: 1rem;
      margin-left: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    .ModernizationAssessmentPageWrapper .navigationBar {
      position: fixed;
      top: auto;
      bottom: 2rem;
      /* flex-direction: column; */
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 0px;
      padding-bottom: 0px;
      width: 94%;
    }

    .ModernizationAssessmentPageWrapper .navigationBar a {
      margin: 1rem 0;
      font-size: x-large;
      /* border-left: 0.5rem;
      border-right: 0.5rem; */
    }

    .welcomeModernization {
      font-size: 70px; /* Reduce hero text size */
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .subHeading h2 {
      font-size: 80px;
    }

    .subHeading p {
      font-size: 40px;
    }

    .ModernizationAssessmentBody  #whoDiv, 
    .ModernizationAssessmentBody  #whatDiv,
    .ModernizationAssessmentBody  #whenDiv,
    .ModernizationAssessmentBody  #whereDiv,
    .ModernizationAssessmentBody  #whyDiv,
    .ModernizationAssessmentBody  #howDiv {
      padding-top: 5rem;
      padding-bottom: 2rem;
      margin-right: 1rem;
      margin-left: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media (min-width: 1281px) and (max-width: 1545px) {
    .ModernizationAssessmentPageWrapper .navigationBar {
      position: fixed;
      top: auto;
      bottom: 2rem;
      /* flex-direction: column; */
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 0px;
      padding-bottom: 0px;
      width: 94%;
    }

    .ModernizationAssessmentPageWrapper .navigationBar a {
      margin: 1rem 0;
      font-size: x-large;
      /* border-left: 0.5rem;
      border-right: 0.5rem; */
    }

    .welcomeModernization {
      font-size: 70px; /* Reduce hero text size */
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .subHeading h2 {
      font-size: 80px;
    }

    .subHeading p {
      font-size: 40px;
    }

    .ModernizationAssessmentBody  #whoDiv, 
    .ModernizationAssessmentBody  #whatDiv,
    .ModernizationAssessmentBody  #whenDiv,
    .ModernizationAssessmentBody  #whereDiv,
    .ModernizationAssessmentBody  #whyDiv,
    .ModernizationAssessmentBody  #howDiv {
      padding-top: 5rem;
      padding-bottom: 2rem;
      margin-right: 1rem;
      margin-left: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media (min-width: 1546px) and (max-width: 1610px) {
    .ModernizationAssessmentPageWrapper .navigationBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
      padding: 1rem 2rem;
      position: sticky;
      top: 2rem;
      backdrop-filter: blur(70px);
      border-radius: 15px;
      margin-left: 30rem;
      margin-right: 30rem;
      margin-top: 0rem;
      width: 40%;
  }
  
  .welcomeModernization {
      text-align: center;
      font-size: 75px;
      font-weight: bold;
      color: white;
      padding: 0rem;
      margin-left: 17rem;
      margin-right: 17rem;
      padding-top: 10rem;
      padding-bottom: 12rem
  }
  
  .subHeading {
      text-align: center;
    }
    
    .welcomeWords h1 {
      font-size: 75px;
      font-weight: bolder;
      padding-bottom: 0%;
      margin-bottom: 0%;
    }
    
    .subHeading h2 {
      font-size: 60px;
      color: white;
      font-weight: bolder;
      padding-top: 0%;
      margin-top: 0%;
      padding-bottom: 0%;
      margin-bottom: 0%;
  
    }
  
    .subHeading p,li,ol {
      font-size: 30px;
      color: #f0f0f0;
      padding-top: 0%;
      margin-top: 0%;
      padding-bottom: 0%;
      margin-bottom: 0%;
  
    }
  
    
    .welcomeWords h3 {
      color: #6e6e73;
      font-size: 30px;
      font-weight: bold;
      padding-top: 1rem;
      margin-top: 0%;
    }
    
    
    .ModernizationAssessmentBody  #whoDiv{
      display: flex;
      margin-top: 5rem;
      margin-right: 3rem;
      margin-bottom: 3rem;
      justify-content: space-around;
      background-color: rgba(0, 0, 0, 0.5);
      border-style:solid;
      border-width: 1px;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 3rem;
      padding-top: 6rem;
      height: 20vh; /* Adjust as needed */
    }
    
    .ModernizationAssessmentBody  #whatDiv{
      display: flex;
      margin-top: 5rem;
      margin-right: 3rem;
      margin-bottom: 3rem;
      justify-content: space-around;
      background-color: rgba(0, 0, 0, 0.5);
      /* border-color: #FD7E14; */
      border-style: solid;
      border-width: 1px;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 3rem;
      padding-top: 6rem;
      height: 20vh; /* Adjust as needed */
    }
    .ModernizationAssessmentBody  #whenDiv{
      display: flex;
      margin-top: 5rem;
      margin: 3rem;
      justify-content: space-around;
      background-color: rgba(0, 0, 0, 0.5);
      /* border-color: #28A745; */
      border-style: solid;
      border-width: 1px;    
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 3rem;
      padding-top: 6rem;
      height: 18vh; /* Adjust as needed */
    }
    .ModernizationAssessmentBody  #whereDiv{
      display: flex;
      margin-top: 5rem;
      margin: 3rem;
      justify-content: space-around;
      background-color: rgba(0, 0, 0, 0.5);
      /* border-color: #FFC107; */
      border-style: solid;
      border-width: 1px;    
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 3rem;
      padding-top: 6rem;
      height: 15vh; /* Adjust as needed */
    }
    .ModernizationAssessmentBody  #whyDiv{
      display: flex;
      margin-top: 5rem;
      margin: 3rem;
      justify-content: space-around;
      background-color: rgba(0, 0, 0, 0.5);
      /* border-color: #DC3545; */
      border-style: solid;
      border-width: 1px;    
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 3rem;
      padding-top: 6rem;
      height: 15vh; /* Adjust as needed */
    }
    .ModernizationAssessmentBody  #howDiv{
      display: flex;
      margin-top: 5rem;
      margin: 3rem;
      justify-content: space-around;
      background-color: rgba(0, 0, 0, 0.5);
      /* border-color: #6C757D; */
      border-style: solid;
      border-width: 1px;    
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 3rem;
      padding-top: 6rem;
      height: 20vh; /* Adjust as needed */
    }
    
    #gradientTextModAssess{
      background: linear-gradient(to right, #FF0000, #FF3333, #FF6666, #FF9999, #FFCCCC);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
  
    a {
      color: black;
      text-decoration: none;
    }
    
    a:hover {
      color: #0070c9 !important;
    }
  }