.TrainingPageWrapper {
    background-color: black;
    background: linear-gradient(to left, #003300 5%, black 100%);

}

.coursesSection {
    display: flex;
    margin-top: 5rem;
    margin: 3rem;
    justify-content: space-around;
    background-color: transparent;
    border-radius: 3rem;
    height: 50vh; /* Adjust as needed */
  }

.TrainingPageWrapper .navigationBar {
    background-color: #161617 !important;
  }

.TrainingPageWrapper .navigationBar {
    display: flex;
    /* width: 15rem; */
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
    padding: 1rem 2rem;
    position: sticky;
    top: 2rem;
    backdrop-filter: blur(70px);
    border-radius: 15px;
    margin-left: 30rem;
    margin-right: 30rem;
    margin-top: 0rem;
}

.welcomeTraining {
    text-align: center;
    font-size: 75px;
    font-weight: bold;
    color: white;
    padding: 0rem;
    margin-left: 17rem;
    margin-right: 17rem;
    padding-top: 7rem;
    padding-bottom: 4rem
}

#gradientTextTraining {
    background: linear-gradient(to right, #00FF00, #33FF33, #66FF66, #99FF99, #CCFFCC);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.titlesForDisplay {
    color: #fff; /* White text for better contrast against dark background */
    font-size: 1.5rem; /* Larger font size */
    font-weight: bold; /* Bold text */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for 3D effect */
    margin-left: 4rem; /* Space from the left */
}

.titlesForDisplay h2 {
    margin-bottom: 20px; /* Space below the title */
}

/* Responsive for mobile devices */
@media (max-width: 767px) {
    .TrainingPageWrapper .navigationBar {
      position: fixed;
      top: auto;
      bottom: 2rem;
      /* flex-direction: column; */
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 0px;
      padding-bottom: 0px;
      width: 85%;
    }

    .TrainingPageWrapper .navigationBar a {
      margin: 1rem 0;
      font-size: small;
      /* border-left: 0.5rem;
      border-right: 0.5rem; */
    }

    .welcomeTraining {
      font-size: 25px; /* Reduce hero text size */
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .coursesSection {
        flex-direction: column;
      }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .TrainingPageWrapper .navigationBar {
      position: fixed;
      top: auto;
      bottom: 2rem;
      /* flex-direction: column; */
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 0px;
      padding-bottom: 0px;
      width: 92%;
    }

    .TrainingPageWrapper .navigationBar a {
      margin: 1rem 0;
      font-size: large;
      /* border-left: 0.5rem;
      border-right: 0.5rem; */
    }

    .welcomeTraining {
      font-size: 55px; /* Reduce hero text size */
      margin-left: 1rem;
      margin-right: 1rem;
      padding-bottom: 22rem;
    }
    
    .coursesSection {
        flex-direction: column;
      }
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    .TrainingPageWrapper .navigationBar {
      position: fixed;
      top: auto;
      bottom: 2rem;
      /* flex-direction: column; */
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 0px;
      padding-bottom: 0px;
      width: 93%;
    }

    .TrainingPageWrapper .navigationBar a {
      margin: 1rem 0;
      font-size: x-large;
      /* border-left: 0.5rem;
      border-right: 0.5rem; */
    }

    .welcomeTraining {
      font-size: 70px; /* Reduce hero text size */
      margin-left: 1rem;
      margin-right: 1rem;
      padding-bottom: 24rem;
    }
    
    .coursesSection {
        flex-direction: column;
      }
  }

  @media (min-width: 1281px) and (max-width: 1545px) {
    .TrainingPageWrapper .navigationBar {
      position: fixed;
      top: auto;
      bottom: 2rem;
      /* flex-direction: column; */
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: 0px;
      padding-bottom: 0px;
      width: 95%;
    }

    .TrainingPageWrapper .navigationBar a {
      margin: 1rem 0;
      font-size: x-large;
      /* border-left: 0.5rem;
      border-right: 0.5rem; */
    }

    .welcomeTraining {
      font-size: 55px; /* Reduce hero text size */
      margin-left: 1rem;
      margin-right: 1rem;
      padding-bottom: 22rem;
    }
    
    .coursesSection {
        flex-direction: column;
      }
  }